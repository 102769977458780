// src/utils/dateUtils.ts
import { DateTime } from "luxon";

export const getCountdown = () => {
    const now = DateTime.now().setZone("Europe/Amsterdam");
    const target = DateTime.fromObject(
        {
            year: 2025,
            month: 1,
            day: 2,
            hour: 13,
            minute: 36,
            second: 59,
        },
        { zone: "Europe/Amsterdam" }
    );

    const diff = target
        .diff(now, ["days", "hours", "minutes", "seconds"])
        .toObject();

    return {
        days: Math.floor(diff.days ?? 0),
        hours: Math.floor(diff.hours ?? 0),
        minutes: Math.floor(diff.minutes ?? 0),
        seconds: Math.floor(diff.seconds ?? 0),
    };
};

export const isCountdownComplete = () => {
    const now = DateTime.now().setZone("Europe/Amsterdam");
    const target = DateTime.fromObject(
        {
            year: 2025,
            month: 1,
            day: 2,
            hour: 13,
            minute: 36,
            second: 59,
        },
        { zone: "Europe/Amsterdam" }
    );

    return now >= target;
};
