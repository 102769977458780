import React from "react";
import {
    Box,
    Container,
    Grid,
    Typography,
    Paper,
    Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { Star, Book, Users, Sparkles } from "lucide-react";
import arjen from "./arjen.jpeg";
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    background:
        theme.palette.mode === "dark"
            ? "rgba(20, 27, 45, 0.7)" // Lighter space blue with transparency
            : theme.palette.background.paper,
    position: "relative",
    overflow: "hidden",
    backdropFilter: "blur(10px)",
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background:
            theme.palette.mode === "dark"
                ? `linear-gradient(135deg, ${theme.palette.background.paper} 0%, rgba(183, 148, 244, 0.05) 100%)`
                : `linear-gradient(135deg, ${theme.palette.background.paper} 0%, rgba(183, 148, 244, 0.1) 100%)`,
    },
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
    width: 240,
    height: 240,
    border: `4px solid ${theme.palette.primary.main}`,
    boxShadow:
        theme.palette.mode === "dark"
            ? "0 0 30px rgba(183, 148, 244, 0.3)"
            : "0 8px 32px rgba(183, 148, 244, 0.2)",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
        width: 180,
        height: 180,
    },
}));

const AchievementItem = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& svg": {
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main,
    },
}));

export const FacilitatorSection: React.FC = () => {
    return (
        <Box
            sx={{
                py: 8,
                background: (theme) =>
                    theme.palette.mode === "dark"
                        ? "linear-gradient(180deg, rgba(183, 148, 244, 0.03) 0%, rgba(20, 27, 45, 0) 100%)"
                        : "linear-gradient(180deg, rgba(183, 148, 244, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
            }}
        >
            <Container maxWidth="lg">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8 }}
                >
                    <Typography
                        variant="h2"
                        align="center"
                        gutterBottom
                        sx={{
                            mb: 6,
                            color: "primary.main",
                            textShadow: (theme) =>
                                theme.palette.mode === "dark"
                                    ? "0 0 20px rgba(183, 148, 244, 0.3)"
                                    : "none",
                        }}
                    >
                        Meet Your Guide
                    </Typography>

                    <StyledPaper elevation={0}>
                        <Grid
                            container
                            spacing={6}
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.6, delay: 0.2 }}
                                >
                                    <LargeAvatar
                                        src={arjen}
                                        alt="Arjen van Berkum"
                                    />
                                </motion.div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={8}
                            >
                                <Box>
                                    <motion.div
                                        initial={{ opacity: 0, x: 20 }}
                                        whileInView={{ opacity: 1, x: 0 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            duration: 0.6,
                                            delay: 0.4,
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            gutterBottom
                                            sx={{
                                                mb: 3,
                                                color: "primary.main",
                                                textShadow: (theme) =>
                                                    theme.palette.mode ===
                                                    "dark"
                                                        ? "0 0 20px rgba(183, 148, 244, 0.3)"
                                                        : "none",
                                            }}
                                        >
                                            Arjen van Berkum
                                        </Typography>

                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "primary.light",
                                                mb: 3,
                                                fontStyle: "italic",
                                                fontFamily:
                                                    '"Cormorant Garamond", serif',
                                            }}
                                        >
                                            Master Guide of The Genius Club
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            sx={{
                                                mb: 4,
                                                color: "text.primary",
                                            }}
                                        >
                                            A visionary leader in personal
                                            transformation, Arjen combines deep
                                            wisdom with practical expertise to
                                            guide ambitious professionals
                                            through their journey of growth and
                                            discovery. His unique approach
                                            bridges ancient wisdom with modern
                                            excellence.
                                        </Typography>

                                        <Box sx={{ mt: 4 }}>
                                            <AchievementItem>
                                                <Star size={24} />
                                                <Typography variant="body1">
                                                    Pioneering expert in
                                                    professional transformation
                                                </Typography>
                                            </AchievementItem>

                                            <AchievementItem>
                                                <Book size={24} />
                                                <Typography variant="body1">
                                                    Curator of transformative
                                                    knowledge and wisdom
                                                </Typography>
                                            </AchievementItem>

                                            <AchievementItem>
                                                <Users size={24} />
                                                <Typography variant="body1">
                                                    Mentor to exceptional
                                                    professionals and leaders
                                                </Typography>
                                            </AchievementItem>

                                            <AchievementItem>
                                                <Sparkles size={24} />
                                                <Typography variant="body1">
                                                    Creator of The Genius Club
                                                    methodology
                                                </Typography>
                                            </AchievementItem>
                                        </Box>
                                    </motion.div>
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledPaper>
                </motion.div>
            </Container>
        </Box>
    );
};

export default FacilitatorSection;
