// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2732%27 height=%2732%27 viewBox=%270 0 960 960%27%3E%3Cg%3E%3Cpath fill=%27%23333%27 d=%27M798.5 798.5c-10.5 10.5-27.6 10.5-38.1 0l-410-410 38.1-38.1 410 410c10.5 10.5 10.5 27.6 0 38.1z%27/%3E%3Cpath fill=%27%234D4D4D%27 d=%27M771.8 771.8c-10.5 10.5-27.6 10.5-38.1 0l-410-410 38.1-38.1 410 410c10.5 10.5 10.5 27.6 0 38.1z%27/%3E%3Cpath fill=%27%23FBB03B%27 d=%27M256.5 245l51.4 26.2c3.3 1.7 7.3 1 9.9-1.6l40.8-40.8c5.9-5.9 15.8-0.8 14.5 7.4l-9 57c-0.6 3.7 1.3 7.3 4.6 9l51.4 26.2c7.4 3.8 5.6 14.8-2.5 16.1l-57 9c-3.7 0.6-6.5 3.4-7.1 7.1l-9 57c-1.3 8.2-12.3 9.9-16.1 2.5l-26.2-51.4c-1.7-3.3-5.3-5.1-9-4.6l-57 9c-8.2 1.3-13.2-8.6-7.4-14.5l40.8-40.8c2.6-2.6 3.3-6.6 1.6-9.9L245 256.5c-3.8-7.4 4.1-15.3 11.5-11.5z%27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), auto;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes sparkleAnimation {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qDAAmxB;AACrxB;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE,yCAAyC;IACzC,UAAU;EACZ;EACA;IACE,yCAAyC;IACzC,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["* {\n  cursor: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 960 960'%3E%3Cg%3E%3Cpath fill='%23333' d='M798.5 798.5c-10.5 10.5-27.6 10.5-38.1 0l-410-410 38.1-38.1 410 410c10.5 10.5 10.5 27.6 0 38.1z'/%3E%3Cpath fill='%234D4D4D' d='M771.8 771.8c-10.5 10.5-27.6 10.5-38.1 0l-410-410 38.1-38.1 410 410c10.5 10.5 10.5 27.6 0 38.1z'/%3E%3Cpath fill='%23FBB03B' d='M256.5 245l51.4 26.2c3.3 1.7 7.3 1 9.9-1.6l40.8-40.8c5.9-5.9 15.8-0.8 14.5 7.4l-9 57c-0.6 3.7 1.3 7.3 4.6 9l51.4 26.2c7.4 3.8 5.6 14.8-2.5 16.1l-57 9c-3.7 0.6-6.5 3.4-7.1 7.1l-9 57c-1.3 8.2-12.3 9.9-16.1 2.5l-26.2-51.4c-1.7-3.3-5.3-5.1-9-4.6l-57 9c-8.2 1.3-13.2-8.6-7.4-14.5l40.8-40.8c2.6-2.6 3.3-6.6 1.6-9.9L245 256.5c-3.8-7.4 4.1-15.3 11.5-11.5z'/%3E%3C/g%3E%3C/svg%3E\"), auto;\n}\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@keyframes sparkleAnimation {\n  from {\n    transform: translate(-50%, -50%) scale(1);\n    opacity: 1;\n  }\n  to {\n    transform: translate(-50%, -50%) scale(0);\n    opacity: 0;\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
