// src/components/ParticleBackground.tsx
import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";

interface Particle {
    x: number;
    y: number;
    size: number;
    speedX: number;
    speedY: number;
    opacity: number;
    color?: string;
    life?: number;
    maxLife?: number;
    pulsePhase?: number;
    pulseSpeed?: number;
}

export const ParticleBackground: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const theme = useTheme();
    const isDark = theme.palette.mode === "dark";

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const particles: Particle[] = [];
        let animationFrameId: number;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        const getStarColor = () => {
            const colors = [
                "rgba(226, 232, 240, ", // Bright white
                "rgba(183, 148, 244, ", // Cosmic purple
                "rgba(214, 188, 250, ", // Light purple
                "rgba(144, 205, 244, ", // Blue star
                "rgba(236, 201, 75, ", // Gold star (rare)
            ];
            const weights = [6, 4, 4, 3, 1]; // Making white more common, gold rare

            let total = weights.reduce((a, b) => a + b, 0);
            let random = Math.random() * total;

            for (let i = 0; i < weights.length; i++) {
                if (random < weights[i]) return colors[i];
                random -= weights[i];
            }
            return colors[0];
        };

        const createParticle = (): Particle => {
            const baseParticle = {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                size: Math.random() * 2 + 0.5,
                speedX: (Math.random() - 0.5) * 0.3,
                speedY: (Math.random() - 0.5) * 0.3,
                opacity: Math.random() * 0.5 + 0.2,
            };

            if (isDark) {
                return {
                    ...baseParticle,
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    size: Math.random() * 2 + 0.5, // Varying star sizes
                    speedX: (Math.random() - 0.5) * 0.1, // Slower movement
                    speedY: (Math.random() - 0.5) * 0.1,
                    color: getStarColor(),
                    life: 0,
                    maxLife: Math.random() * 200 + 100, // Longer life for twinkling effect
                    pulsePhase: Math.random() * Math.PI * 2, // For twinkling
                    pulseSpeed: Math.random() * 0.02 + 0.01, // Twinkle speed
                    opacity: Math.random() * 0.5 + 0.5, // Brighter stars
                };
            }

            return baseParticle;
        };

        const initParticles = () => {
            const particleCount = Math.floor(
                (canvas.width * canvas.height) / (isDark ? 8000 : 15000)
            );
            for (let i = 0; i < particleCount; i++) {
                particles.push(createParticle());
            }
        };

        const drawParticle = (particle: Particle) => {
            if (!ctx) return;

            if (isDark && particle.color && particle.life !== undefined) {
                const twinkle = particle.pulsePhase
                    ? Math.sin(
                          particle.pulsePhase +
                              particle.life * (particle.pulseSpeed || 0.02)
                      ) *
                          0.5 +
                      0.5
                    : 1;

                // Save the current context state
                ctx.save();

                // Create star glow
                ctx.beginPath();
                ctx.arc(
                    particle.x,
                    particle.y,
                    particle.size * (0.5 + twinkle * 0.5),
                    0,
                    Math.PI * 2
                );

                // Create and apply gradient for star glow
                const gradient = ctx.createRadialGradient(
                    particle.x,
                    particle.y,
                    0,
                    particle.x,
                    particle.y,
                    particle.size * 2
                );
                gradient.addColorStop(0, `${particle.color}${0.8 * twinkle})`);
                gradient.addColorStop(1, `${particle.color}0)`);

                ctx.fillStyle = gradient;
                ctx.fill();

                // Draw the star core
                ctx.beginPath();
                ctx.arc(
                    particle.x,
                    particle.y,
                    particle.size * 0.5 * (0.8 + twinkle * 0.2),
                    0,
                    Math.PI * 2
                );
                ctx.fillStyle = `${particle.color}${
                    particle.opacity * twinkle
                })`;
                ctx.fill();

                // Optional: Add cross-shaped light rays for bigger stars
                if (particle.size > 1.5) {
                    ctx.beginPath();
                    ctx.moveTo(particle.x - particle.size * 2, particle.y);
                    ctx.lineTo(particle.x + particle.size * 2, particle.y);
                    ctx.moveTo(particle.x, particle.y - particle.size * 2);
                    ctx.lineTo(particle.x, particle.y + particle.size * 2);
                    ctx.strokeStyle = `${particle.color}${0.1 * twinkle})`;
                    ctx.lineWidth = 0.5;
                    ctx.stroke();
                }

                // Restore the context state
                ctx.restore();
            } else {
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
                ctx.fillStyle = `rgba(197, 165, 114, ${particle.opacity})`;
                ctx.fill();
            }
        };

        const updateParticle = (particle: Particle) => {
            if (
                isDark &&
                particle.life !== undefined &&
                particle.maxLife !== undefined
            ) {
                particle.life++;

                if (particle.life > particle.maxLife) {
                    // Reset particle for continuous twinkling
                    particle.life = 0;
                    particle.pulsePhase = Math.random() * Math.PI * 2;
                }

                // Very slow movement for stars
                particle.x += particle.speedX;
                particle.y += particle.speedY;

                // Wrap around screen edges
                if (particle.x < 0) particle.x = canvas.width;
                if (particle.x > canvas.width) particle.x = 0;
                if (particle.y < 0) particle.y = canvas.height;
                if (particle.y > canvas.height) particle.y = 0;
            } else {
                particle.x += particle.speedX;
                particle.y += particle.speedY;

                if (particle.x < 0) particle.x = canvas.width;
                if (particle.x > canvas.width) particle.x = 0;
                if (particle.y < 0) particle.y = canvas.height;
                if (particle.y > canvas.height) particle.y = 0;
            }
        };

        const render = () => {
            if (!ctx) return;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.forEach((particle) => {
                updateParticle(particle);
                drawParticle(particle);
            });

            animationFrameId = requestAnimationFrame(render);
        };

        resizeCanvas();
        initParticles();
        render();

        window.addEventListener("resize", resizeCanvas);

        return () => {
            window.removeEventListener("resize", resizeCanvas);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isDark]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                pointerEvents: "none",
                zIndex: 0,
            }}
        />
    );
};

export default ParticleBackground;
