import React, { useState, useEffect } from "react";
import {
    Box,
    Paper,
    TextField,
    Button,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    Stack,
    FormGroup,
    FormLabel,
} from "@mui/material";
import SuccessSection from "./SuccessSection";

const bookList = [
    "thinking, fast & slow",
    "against the gods",
    "the serendipity mindset",
    "the black swan",
    "7 habits of highly effective people",
    "purple cow",
    "theory of constraints",
    "leading from the middle: playbook for managers to influence up, down and across the organization",
    "meditations",
];

const startingDates = ["march 2025", "november 2025", "no preference"];

interface FormData {
    name: string;
    language: string;
    startDate: string;
    bookFormat: string;
    ownedBooks: string[];
    motivation: string;
    additionalRemarks: string;
}

const SubscriptionForm: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: "",
        language: "",
        startDate: "",
        bookFormat: "paper",
        ownedBooks: [],
        motivation: "",
        additionalRemarks: "",
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    // Check for submission status in cookies on component mount
    useEffect(() => {
        const submissionStatus = document.cookie
            .split("; ")
            .find((row) => row.startsWith("geniusClubSubmitted="));

        if (submissionStatus) {
            setIsSubmitted(submissionStatus.split("=")[1] === "true");
        }
    }, []);

    const handleOwnedBookChange = (book: string) => {
        setFormData((prev) => ({
            ...prev,
            ownedBooks: prev.ownedBooks.includes(book)
                ? prev.ownedBooks.filter((b) => b !== book)
                : [...prev.ownedBooks, book],
        }));
    };

    const setSubmissionCookie = () => {
        // Set cookie to expire in 365 days
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 365);
        document.cookie = `geniusClubSubmitted=true; expires=${expiryDate.toUTCString()}; path=/`;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Create FormData object for the backend
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("motivation", formData.motivation);
        formDataToSend.append(
            "wants_digital_book",
            (
                formData.bookFormat === "ebook" ||
                formData.bookFormat === "audio"
            ).toString()
        );
        formDataToSend.append(
            "preferred_language",
            formData.language === "nl" ? "dutch" : "english"
        );

        // Convert start date to proper format
        if (formData.startDate === "march 2025") {
            formDataToSend.append("preferred_starting_date", "2025-03-01");
        } else if (formData.startDate === "november 2025") {
            formDataToSend.append("preferred_starting_date", "2025-11-01");
        }

        // Add new fields
        formDataToSend.append("owned_books", formData.ownedBooks.join(","));
        formDataToSend.append("book_format", formData.bookFormat);
        formDataToSend.append(
            "additional_remarks",
            formData.additionalRemarks
        );

        try {
            const response = await fetch("/api/register", {
                method: "POST",
                body: formDataToSend,
            });

            const data = await response.json();

            if (response.ok) {
                setSubmissionCookie();
                setIsSubmitted(true);
                setFormData({
                    name: "",
                    language: "",
                    startDate: "",
                    bookFormat: "paper",
                    ownedBooks: [],
                    motivation: "",
                    additionalRemarks: "",
                });
            } else {
                alert(`Registration failed: ${data.error}`);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert(
                "An error occurred while submitting the form. Please try again."
            );
        }
    };

    if (isSubmitted) {
        return <SuccessSection />;
    }

    return (
        <Paper
            elevation={0}
            sx={{
                p: 4,
                maxWidth: 800,
                mx: "auto",
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: 2,
            }}
        >
            <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{
                    textAlign: "center",
                    color: "primary.main",
                    mb: 4,
                    textTransform: "lowercase",
                }}
            >
                join the genius club
            </Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                    {/* Name */}
                    <TextField
                        label="what's your name?"
                        fullWidth
                        required
                        value={formData.name}
                        onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                        }
                        InputProps={{ sx: { textTransform: "lowercase" } }}
                    />

                    {/* Language Preference */}
                    <FormControl component="fieldset">
                        <FormLabel sx={{ mb: 1, textTransform: "lowercase" }}>
                            in which language do you prefer to read and do the
                            sessions?
                        </FormLabel>
                        <RadioGroup
                            value={formData.language}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    language: e.target.value,
                                })
                            }
                        >
                            <FormControlLabel
                                value="nl"
                                control={<Radio />}
                                label="dutch"
                            />
                            <FormControlLabel
                                value="en"
                                control={<Radio />}
                                label="english"
                            />
                            <FormControlLabel
                                value="no_preference"
                                control={<Radio />}
                                label="no preference"
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* Starting Date */}
                    <FormControl component="fieldset">
                        <FormLabel sx={{ mb: 1, textTransform: "lowercase" }}>
                            do you have a preferred starting date?
                        </FormLabel>
                        <RadioGroup
                            value={formData.startDate}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    startDate: e.target.value,
                                })
                            }
                        >
                            {startingDates.map((date) => (
                                <FormControlLabel
                                    key={date}
                                    value={date}
                                    control={<Radio />}
                                    label={date}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    {/* Book Format */}
                    <FormControl component="fieldset">
                        <FormLabel sx={{ mb: 1, textTransform: "lowercase" }}>
                            would you prefer the option of an e-book or
                            audiobook, perhaps due to dyslexia or other
                            preferences?
                        </FormLabel>
                        <RadioGroup
                            value={formData.bookFormat}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    bookFormat: e.target.value,
                                })
                            }
                        >
                            <FormControlLabel
                                value="paper"
                                control={<Radio />}
                                label="paper (default)"
                            />
                            <FormControlLabel
                                value="ebook"
                                control={<Radio />}
                                label="e-book"
                            />
                            <FormControlLabel
                                value="audio"
                                control={<Radio />}
                                label="audio book"
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* Owned Books */}
                    <FormControl component="fieldset">
                        <FormLabel sx={{ mb: 1, textTransform: "lowercase" }}>
                            do you already own any of these books? (select all
                            that apply)
                        </FormLabel>
                        <FormGroup>
                            {bookList.map((book) => (
                                <FormControlLabel
                                    key={book}
                                    control={
                                        <Checkbox
                                            checked={formData.ownedBooks.includes(
                                                book
                                            )}
                                            onChange={() =>
                                                handleOwnedBookChange(book)
                                            }
                                        />
                                    }
                                    label={book}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>

                    {/* Motivation */}
                    <TextField
                        label="what's your reason / motivation to sign up for the genius club?"
                        fullWidth
                        required
                        multiline
                        rows={4}
                        value={formData.motivation}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                motivation: e.target.value,
                            })
                        }
                        InputProps={{ sx: { textTransform: "lowercase" } }}
                    />

                    {/* Additional Remarks */}
                    <TextField
                        label="any other questions / remarks we need to know?"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.additionalRemarks}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                additionalRemarks: e.target.value,
                            })
                        }
                        InputProps={{ sx: { textTransform: "lowercase" } }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                            mt: 2,
                            py: 1.5,
                            textTransform: "lowercase",
                        }}
                    >
                        submit application
                    </Button>
                </Stack>
            </form>
        </Paper>
    );
};

export default SubscriptionForm;
