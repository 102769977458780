import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";

export const HeroSection: React.FC = () => {
    return (
        <Box
            sx={{
                pt: 6,
                pb: 4,
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    sx={{
                        fontSize: {
                            xs: "2.5rem",
                            sm: "3rem",
                            md: "3.5rem",
                        },
                        fontWeight: 600,
                        mb: 3,
                        color: "primary.main",
                        textShadow: (theme) =>
                            theme.palette.mode === "dark"
                                ? "0 0 20px rgba(183, 148, 244, 0.3)"
                                : "none",
                    }}
                >
                    The Genius Club
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    color="text.secondary"
                    sx={{
                        maxWidth: "800px",
                        mx: "auto",
                        mb: 6,
                        fontFamily: (theme) =>
                            theme.typography.body1.fontFamily,
                        fontSize: {
                            xs: "1.1rem",
                            sm: "1.2rem",
                            md: "1.3rem",
                        },
                    }}
                >
                    Embark on a transformative journey of wisdom and
                    excellence. Join an exclusive circle of minds dedicated to
                    personal and professional mastery.
                </Typography>
            </Container>
        </Box>
    );
};
