import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { HeroSection } from "../components/landing/HeroSection";
import { CountdownSection } from "../components/landing/CountdownTimer";
import { MetricsDisplay } from "../components/landing/MetricsDisplay";
import { FacilitatorSection } from "../components/landing/FacilitatorSection";
import ParticleBackground from "../components/common/ParticleBackground";
import SubscriptionForm from "../components/landing/SubscriptionForm";

const LandingPage: React.FC = () => {
    return (
        <Box
            sx={{
                minHeight: "100vh",
                background: (theme) => theme.palette.background.default,
                position: "relative",
            }}
        >
            <ParticleBackground />
            <Box sx={{ position: "relative", zIndex: 1 }}>
                <HeroSection />
                <CountdownSection />
                <Box sx={{ mt: 16 }}>
                    {" "}
                    {/* Increased spacing before metrics */}
                    <MetricsDisplay />
                </Box>
                {/* <SubscriptionForm /> */}
                <FacilitatorSection />
            </Box>
        </Box>
    );
};

export default LandingPage;
