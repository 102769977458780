import React, { useState, useEffect } from "react";
import { Box, Container, Paper, Typography, Grid } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { getCountdown, isCountdownComplete } from "../../utils/dateUtils";
import SubscriptionForm from "./SubscriptionForm";

interface TimeUnit {
    label: string;
    value: number;
}

const CountdownUnit: React.FC<TimeUnit> = ({ label, value }) => {
    const formattedValue = value.toString().padStart(2, "0");

    return (
        <Paper
            elevation={0}
            sx={{
                p: { xs: 2, md: 3 },
                textAlign: "center",
                background: "transparent",
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: 2,
                minWidth: { xs: "80px", sm: "120px", md: "160px" },
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontWeight: 600,
                    color: "primary.main",
                    fontFamily: '"Cormorant Garamond", serif',
                    fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4.5rem" },
                }}
            >
                {formattedValue}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    color: "text.secondary",
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                    mt: 1,
                    fontSize: { xs: "0.8rem", sm: "1rem", md: "1.1rem" },
                }}
            >
                {label}
            </Typography>
        </Paper>
    );
};

export const CountdownSection: React.FC = () => {
    const [timeLeft, setTimeLeft] = useState(getCountdown());
    const [isComplete, setIsComplete] = useState(isCountdownComplete());

    useEffect(() => {
        const timer = setInterval(() => {
            if (isCountdownComplete()) {
                setIsComplete(true);
                clearInterval(timer);
            } else {
                setTimeLeft(getCountdown());
            }
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Container
            maxWidth="lg"
            sx={{ mt: 8, mb: 4 }}
        >
            <AnimatePresence mode="wait">
                {!isComplete ? (
                    <motion.div
                        key="countdown"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Box
                            textAlign="center"
                            mb={6}
                        >
                            <Typography
                                variant="h3"
                                component="h2"
                                gutterBottom
                                sx={{
                                    fontWeight: 500,
                                    color: "primary.main",
                                    fontSize: {
                                        xs: "1.8rem",
                                        sm: "2.2rem",
                                        md: "2.5rem",
                                    },
                                    mb: 4,
                                    textTransform: "lowercase",
                                }}
                            >
                                registration opens in
                            </Typography>
                        </Box>
                        <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <CountdownUnit
                                    label="days"
                                    value={timeLeft.days}
                                />
                            </Grid>
                            <Grid item>
                                <CountdownUnit
                                    label="hours"
                                    value={timeLeft.hours}
                                />
                            </Grid>
                            <Grid item>
                                <CountdownUnit
                                    label="minutes"
                                    value={timeLeft.minutes}
                                />
                            </Grid>
                            <Grid item>
                                <CountdownUnit
                                    label="seconds"
                                    value={timeLeft.seconds}
                                />
                            </Grid>
                        </Grid>
                    </motion.div>
                ) : (
                    <motion.div
                        key="subscription-form"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <SubscriptionForm />
                    </motion.div>
                )}
            </AnimatePresence>
        </Container>
    );
};
