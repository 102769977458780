import {
    createTheme,
    PaletteMode,
    Theme,
    ThemeOptions,
} from "@mui/material/styles";
import { Components } from "@mui/material/styles/components";

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
    palette: {
        mode,
        ...(mode === "light"
            ? {
                  primary: {
                      main: "#B794F4", // Cosmic purple
                      light: "#D6BCFA",
                      dark: "#805AD5",
                  },
                  secondary: {
                      main: "#90CDF4", // Celestial blue
                      light: "#BEE3F8",
                      dark: "#4299E1",
                  },
                  background: {
                      default: "#FFFFFF",
                      paper: "#F8F9FA",
                  },
                  text: {
                      primary: "#2D3748",
                      secondary: "#4A5568",
                  },
              }
            : {
                  primary: {
                      main: "#B794F4", // Cosmic purple
                      light: "#D6BCFA",
                      dark: "#805AD5",
                  },
                  secondary: {
                      main: "#90CDF4", // Celestial blue
                      light: "#BEE3F8",
                      dark: "#4299E1",
                  },
                  background: {
                      default: "#0A0F1E", // Deep space blue
                      paper: "#141B2D", // Lighter space blue
                  },
                  text: {
                      primary: "#E2E8F0",
                      secondary: "#A0AEC0",
                  },
              }),
    },
    typography: {
        fontFamily: '"Cormorant Garamond", "Times New Roman", serif',
        h1: {
            fontWeight: 600,
            letterSpacing: "0.02em",
            color: "#B794F4", // Updated to cosmic purple
        },
        h2: {
            fontWeight: 500,
            letterSpacing: "0.01em",
            color: "#B794F4", // Updated to cosmic purple
        },
        h3: {
            fontWeight: 500,
            color: "#B794F4", // Updated to cosmic purple
        },
        body1: {
            fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
            fontSize: "1.1rem",
        },
        button: {
            fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: "none",
                    padding: "10px 24px",
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "0 4px 20px rgba(183, 148, 244, 0.25)", // Updated to cosmic purple
                    },
                } as const,
                contained: {
                    background:
                        "linear-gradient(45deg, #B794F4 30%, #D6BCFA 90%)", // Updated to cosmic gradient
                    "&:hover": {
                        background:
                            "linear-gradient(45deg, #805AD5 30%, #B794F4 90%)", // Updated to cosmic gradient
                    },
                } as const,
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundImage: "none",
                    ...(theme.palette.mode === "dark" && {
                        backgroundColor: "rgba(31, 41, 55, 0.5)",
                        backdropFilter: "blur(10px)",
                        border: "1px solid rgba(183, 148, 244, 0.1)", // Updated to cosmic purple
                    }),
                }),
            },
        },
    } as Components<Theme>,
});

export const getTheme = (mode: PaletteMode): Theme => {
    const themeOptions = getDesignTokens(mode);
    return createTheme(themeOptions);
};
