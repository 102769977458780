import React from "react";
import { X } from "lucide-react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    IconButton,
} from "@mui/material";

interface GroupScheduleTableProps {
    onClose: () => void;
}

const GroupScheduleTable: React.FC<GroupScheduleTableProps> = ({
    onClose,
}) => {
    return (
        <Box
            sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                maxWidth: "900px",
                height: "80vh",
                zIndex: 1000,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                backdropFilter: "blur(10px)",
                border: "1px solid",
                borderColor: "primary.light",
                bgColor: (theme) =>
                    theme.palette.mode === "dark"
                        ? "rgba(31, 41, 55, 0.9)"
                        : "rgba(255, 255, 255, 0.9)",
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* Close button */}
            <IconButton
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "text.secondary",
                    "&:hover": {
                        color: "text.primary",
                    },
                }}
                size="large"
                aria-label="close"
            >
                <X size={24} />
            </IconButton>

            <Typography
                variant="h5"
                component="h2"
                sx={{
                    mb: 3,
                    color: "primary.main",
                    textAlign: "center",
                    fontWeight: 500,
                }}
            >
                Group Schedule Overview
            </Typography>
            <TableContainer
                component={Paper}
                sx={{
                    bgcolor: "transparent",
                    flex: 1,
                    overflow: "auto",
                    "& .MuiTableCell-root": {
                        borderColor: "primary.light",
                        color: "text.primary",
                    },
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                maand
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Groep 1 (EN) 12p
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Groep 2 (NL) 12p
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Groep 3 (EN) 12p
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                Groep 4 (NL) 12p
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[
                            { month: "maart '25", g1: "1", g2: "1" },
                            { month: "april" },
                            { month: "mei", g1: "2", g2: "2" },
                            { month: "juni" },
                            { month: "juli", g1: "3", g2: "3" },
                            { month: "augustus" },
                            { month: "september", g1: "4", g2: "4" },
                            { month: "oktober" },
                            { month: "november", g1: "5", g2: "5", g3: "1" },
                            { month: "december" },
                            {
                                month: "januari '26",
                                g1: "6",
                                g2: "6",
                                g3: "2",
                            },
                            { month: "februari" },
                            {
                                month: "maart",
                                g1: "7",
                                g2: "7",
                                g3: "3",
                                g4: "1",
                            },
                            { month: "april" },
                            {
                                month: "mei",
                                g1: "8",
                                g2: "8",
                                g3: "4",
                                g4: "2",
                            },
                            { month: "juni" },
                            {
                                month: "juli",
                                g1: "9",
                                g2: "9",
                                g3: "5",
                                g4: "3",
                            },
                            { month: "augustus" },
                            { month: "september", g3: "6", g4: "4" },
                            { month: "oktober" },
                            { month: "november", g3: "7", g4: "5" },
                            { month: "december" },
                            { month: "januari '27", g3: "8", g4: "6" },
                            { month: "februari" },
                            { month: "maart", g3: "9", g4: "7" },
                            { month: "april" },
                            { month: "mei", g4: "8" },
                            { month: "juni" },
                            { month: "juli", g4: "9" },
                        ].map((row, index) => (
                            <TableRow
                                key={index}
                                hover
                            >
                                <TableCell>{row.month}</TableCell>
                                <TableCell>{row.g1 || ""}</TableCell>
                                <TableCell>{row.g2 || ""}</TableCell>
                                <TableCell>{row.g3 || ""}</TableCell>
                                <TableCell>{row.g4 || ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default GroupScheduleTable;
