import React, { useState } from "react";
import { motion } from "framer-motion";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import GroupScheduleTable from "./GroupScheduleTable";

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    transition: "all 0.3s ease-in-out",
    background:
        theme.palette.mode === "dark"
            ? "rgba(31, 41, 55, 0.4)"
            : "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(10px)",
    border: `1px solid ${
        theme.palette.mode === "dark"
            ? "rgba(183, 148, 244, 0.2)"
            : "rgba(183, 148, 244, 0.2)"
    }`,
    "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 20px 40px rgba(183, 148, 244, 0.15)",
        border: `1px solid ${
            theme.palette.mode === "dark"
                ? "rgba(183, 148, 244, 0.5)"
                : "rgba(183, 148, 244, 0.5)"
        }`,
        background:
            theme.palette.mode === "dark"
                ? "rgba(31, 41, 55, 0.6)"
                : "rgba(255, 255, 255, 0.9)",
        "& .metric-value": {
            transform: "scale(1.1)",
            color: theme.palette.primary.main,
        },
        "& .metric-highlight": {
            opacity: 1,
        },
    },
}));

const MetricHighlight = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "2px",
    background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}, transparent)`,
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
}));

interface MetricProps {
    value: string | number;
    label: string;
    delay?: number;
    onClick: () => void;
}

const Metric: React.FC<MetricProps> = ({
    value,
    label,
    delay = 0,
    onClick,
}) => {
    const theme = useTheme();

    return (
        <StyledPaper
            elevation={0}
            onClick={onClick}
        >
            <MetricHighlight className="metric-highlight" />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay }}
            >
                <Typography
                    className="metric-value"
                    variant="h4"
                    component="div"
                    sx={{
                        fontWeight: 600,
                        marginBottom: 1,
                        color: theme.palette.primary.main,
                        transition: "all 0.3s ease-in-out",
                        fontSize: { xs: "1.8rem", sm: "2rem" },
                    }}
                >
                    {value}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color:
                            theme.palette.mode === "dark"
                                ? "rgba(255, 255, 255, 0.7)"
                                : theme.palette.text.secondary,
                        fontWeight: 500,
                        position: "relative",
                        zIndex: 1,
                        fontSize: { xs: "0.8rem", sm: "0.9rem" },
                    }}
                >
                    {label}
                </Typography>
            </motion.div>
        </StyledPaper>
    );
};

const metricsData = [
    { value: "9", label: "Transformative Books" },
    { value: "9", label: "Exclusive Sessions" },
    { value: "1.5", label: "Years of Growth" },
    { value: "12", label: "Elite Spots per Group" },
];

export const MetricsDisplay: React.FC = () => {
    const [showTable, setShowTable] = useState(false);
    return (
        <Box
            sx={{
                flexGrow: 1,
                mt: 12, // Increased top margin for more space after countdown
                mb: 4,
                position: "relative",
                zIndex: 1,
                maxWidth: "900px",
                mx: "auto",
                px: 2,
            }}
        >
            <Grid
                container
                spacing={2}
            >
                {metricsData.map((metric, index) => (
                    <Grid
                        item
                        xs={6}
                        sm={3}
                        key={index}
                    >
                        <Metric
                            value={metric.value}
                            label={metric.label}
                            delay={index * 0.2}
                            onClick={() => setShowTable(true)}
                        />
                    </Grid>
                ))}
            </Grid>
            {showTable && (
                <GroupScheduleTable onClose={() => setShowTable(false)} />
            )}
        </Box>
    );
};

export default MetricsDisplay;
