import React, { useState, useEffect } from "react";

interface Sparkle {
    id: number;
    x: number;
    y: number;
    size: number;
    speedX: number;
    speedY: number;
    opacity: number;
    pulsePhase: number;
    life: number;
}

const NEON_PURPLE = "rgba(183, 0, 255, ";

const SparkleTrail: React.FC = () => {
    const [sparkles, setSparkles] = useState<Sparkle[]>([]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const spread = 30;
            const numSparkles = 4;

            for (let i = 0; i < numSparkles; i++) {
                const newSparkle = {
                    id: Date.now() + i,
                    x: e.clientX + (Math.random() - 0.5) * spread,
                    y: e.clientY + (Math.random() - 0.5) * spread,
                    size: Math.random() * 3 + 2,
                    speedX: (Math.random() - 0.5) * 3,
                    speedY: (Math.random() - 0.5) * 3,
                    opacity: Math.random() * 0.3 + 0.7,
                    pulsePhase: Math.random() * Math.PI * 2,
                    life: 0,
                };

                setSparkles((prev) => [...prev, newSparkle]);
            }
        };

        const updateSparkles = () => {
            setSparkles((prev) =>
                prev
                    .map((sparkle) => ({
                        ...sparkle,
                        x: sparkle.x + sparkle.speedX,
                        y: sparkle.y + sparkle.speedY,
                        life: sparkle.life + 1,
                        opacity: sparkle.opacity * 0.95,
                    }))
                    .filter((sparkle) => sparkle.opacity > 0.1)
            );
        };

        const interval = setInterval(updateSparkles, 16);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            clearInterval(interval);
        };
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                inset: 0,
                pointerEvents: "none",
                zIndex: 9999,
            }}
        >
            {sparkles.map((sparkle) => {
                const twinkle =
                    Math.sin(sparkle.pulsePhase + sparkle.life * 0.1) * 0.5 +
                    0.5;
                return (
                    <div
                        key={sparkle.id}
                        style={{
                            position: "absolute",
                            left: sparkle.x,
                            top: sparkle.y,
                            width: sparkle.size * 2,
                            height: sparkle.size * 2,
                            transform: "translate(-50%, -50%)",
                            background: `${NEON_PURPLE}${
                                sparkle.opacity * twinkle
                            })`,
                            borderRadius: "50%",
                            boxShadow: `0 0 ${sparkle.size * 4}px ${
                                sparkle.size * 2
                            }px ${NEON_PURPLE}${
                                sparkle.opacity * twinkle * 0.8
                            })`,
                            filter: "blur(0.5px)",
                            pointerEvents: "none",
                        }}
                    />
                );
            })}
        </div>
    );
};

export default SparkleTrail;
