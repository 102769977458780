import React from "react";
import { Paper, Typography, Box, Stack } from "@mui/material";
import { Star, Sparkles, Rocket } from "lucide-react";

const SuccessSection = () => {
    return (
        <Paper
            elevation={0}
            sx={{
                p: 8,
                maxWidth: 800,
                mx: "auto",
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: 2,
                textAlign: "center",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {/* Decorative elements */}
            <Stack
                spacing={4}
                sx={{ position: "relative", zIndex: 1 }}
            >
                <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                        color: "primary.main",
                        textTransform: "lowercase",
                        fontWeight: "500",
                    }}
                >
                    welcome to the genius club
                </Typography>

                <Typography
                    variant="h6"
                    sx={{
                        color: "text.secondary",
                        textTransform: "lowercase",
                        fontWeight: "400",
                    }}
                >
                    your journey to extraordinary insights begins here
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        color: "text.primary",
                        maxWidth: "600px",
                        mx: "auto",
                        lineHeight: 1.8,
                    }}
                >
                    thank you for joining our intellectual adventure. we'll be
                    in touch soon with more details about your membership and
                    upcoming sessions. get ready to explore fascinating ideas
                    with fellow curious minds.
                </Typography>

                <Box sx={{ mt: 4 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "primary.light",
                            textTransform: "lowercase",
                            fontStyle: "italic",
                        }}
                    >
                        "the beginning of knowledge is the discovery of
                        something we do not understand"
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.secondary",
                            mt: 1,
                        }}
                    >
                        — frank herbert
                    </Typography>
                </Box>
            </Stack>
        </Paper>
    );
};

export default SuccessSection;
