import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { getTheme } from "./theme";
import LandingPage from "./pages/LandingPage";
import SparkleTrail from "./components/common/SparkleTrail";

const ThemedApp: React.FC = () => {
    const theme = getTheme("dark");

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <SparkleTrail />
            <Router>
                <Box sx={{ minHeight: "100vh" }}>
                    <Box>
                        <Routes>
                            <Route
                                path="/"
                                element={<LandingPage />}
                            />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </MuiThemeProvider>
    );
};

const App: React.FC = () => {
    return <ThemedApp />;
};

export default App;
